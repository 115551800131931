.footer {
    margin-top: 18px;
    text-align: center;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.5px;
    color: var(--color-grey);
}

.footer p {
    margin: 0;
    padding-top: 14px;
}

.footer__sign-out-icon {
    display: inline-block;
    position: relative;
    background-image: url("../assets/sign-out.svg");
    background-repeat: no-repeat;
    top: 0.4rem;
    width: 20px;
    height: 21px;
    margin-right: 10px;
}

.footer a {
    color: var(--color-grey);
}