.connected-screen__title {
    margin-bottom: 0;
}

.connected-screen__description {
    margin-top: 16px;
}

.connected-screen__assets {
    margin-top: 34px;
}