.asset {
    color: var(--color-dark-grey);
    background-color: var(--color-white);
    margin-top: 16px;
    padding: 24px 16px;
    border-radius: 8px;
    font-family: Open Sans;
    font-weight: 800;
    font-size: 24px;
}

.asset__name {
    float: left;
    width: 27%;
    overflow: hidden;
    text-overflow: ellipsis;
}

.asset__balance {
    float: right;
    width: 70%;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: right;
}