.Toastify__toast-container {
    width: 344px;
    height: 0;
    padding: 0;
}
.Toastify__toast-container--top-center {
    top: 0;
}

.Toastify__toast {
    position: relative;
    box-sizing: border-box;
    padding: 12px 14px;
    min-height: 48px;
    top: 32px;

    /* 00. On Surface / High Emphasis */
    border-radius: 4px;
    box-shadow: 0 3px 6px rgba(0,0,0,0.3);

    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    letter-spacing: 0.25px;
}

.Toastify__toast--error {
    background: var(--color-dark-red);
    color: var(--color-white);
}

.Toastify__close-button {
    position: absolute;
    width: 16px;
    height: 16px;
    margin: 0;
    right: 14px;
    top: calc(50% - 8px);
    color: var(--color-white);
    opacity: 1;
}