.header {
    padding-top: 47px;
    width: 100%;
    height: 38px;
}

.header__logo {
    float: left;
    width: 155px;
    height: 100%;
    background-image: url('../assets/logo.svg');
    background-size: cover;
    background-repeat: no-repeat;
}

.header__address {
    float: right;
    padding: 14px 14px;
    border: 1px solid #FFFFFF;
    border-radius: 8px;
    margin-top: -8px;
}