html {
  --color-red: #E12942;
  --color-dark-red: #8f1929;
  --color-white-90: rgba(255, 255, 255, 0.9);
  --color-grey: #979797;
  --color-dark-grey: #333;
  --color-black: #000;
  --color-white: #fff;
  --app-width: 450px;
  color: var(--color-white);
  background-color: var(--color-dark-grey);
}

body {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  margin: 0;
}

.app {
  width: var(--app-width);
  margin: 0 auto;
  padding: 0 24px;
}

a {
  color: var(--color-white);
}

h1 {
  margin-top: 42px;
  font-weight: bold;
  font-size: 32px;
  line-height: 44px;
}

p {
  margin-top: 24px;
}

button {
  width: 100%;
  border: 0;
  height: 48px;
  cursor: pointer;
  margin-top: 16px;
  border-radius: 4px;
  font-weight: bold;
  font-size: 18px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 1.25px;
  color: var(--color-white);
  background-color: var(--color-red);
}

button:disabled {
  color: var(--color-white);
  background-color: var(--color-grey);
}